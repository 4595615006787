
//= require turbolinks
//= require bootstrap
//= require_tree .
//= require actiontext
require("trix")
require("@rails/actiontext")


import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "trix"
import "@rails/actiontext"
import "controllers"

Rails.start()
Turbolinks.start()
  ActiveStorage.start()

import autoComplete from "@tarekraafat/autocomplete.js";

document.addEventListener('turbolinks:load', () => {

  const autoCompleteJS = new autoComplete({

    data: {
      src: async () => {
        try {
          // Loading placeholder text
          document
            .getElementById("autoComplete")
            .setAttribute("placeholder", "Loading...");
          // Fetch External Data Source
          const source = await fetch(
             `${window.location.origin}/products.json`
          );
          const data = await source.json();
          // Post Loading placeholder text
          document
            .getElementById("autoComplete")
            .setAttribute("placeholder", autoCompleteJS.placeHolder);
          // Returns Fetched data
          return data;
        } catch (error) {
          return error;
        }
      },
      keys: ["name", "reference"],
      cache: true,
      filter: (list) => {
        // Filter duplicates
        // incase of multiple data keys usage
        const filteredResults = Array.from(
          new Set(list.map((value) => value.match))
        ).map((food) => {
          return list.find((value) => value.match === food);
        });
  
        return filteredResults;
      }
    },
    placeHolder: "Cherchez une pièce...",
    resultsList: {
      element: (list, data) => {
        const info = document.createElement("p");
        if (data.results.length > 0) {
          info.innerHTML = `Affiche <strong>${data.results.length}</strong> parmi <strong>${data.matches.length}</strong> resultat(s)`;
        } else {
          info.innerHTML = `Trouvé <strong>${data.matches.length}</strong> pour la recherche <strong>"${data.query}"</strong>`;
        }
        list.prepend(info);
      },
      noResults: true,
      maxResults: 50,
      tabSelect: true
    },
    resultItem: {
      element: (item, data) => {
        // Modify Results Item Style
        item.style = "display: flex; justify-content: space-between;";
        // Modify Results Item Content
        item.innerHTML = `
        <span style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
          ${data.match}
        </span>
        <span style="display: flex; align-items: center; font-size: 13px; font-weight: 100; text-transform: uppercase; color: rgba(0,0,0,.2);">
          ${data.key}
        </span>`;
      },
      highlight: true
    },
    events: {
      input: {
        focus: () => {
          if (autoCompleteJS.input.value.length) autoCompleteJS.start();
        }
      }
    }
  });


autoCompleteJS.input.addEventListener("selection", function (event) {
	const feedback = event.detail;
	autoCompleteJS.input.blur();
	// Prepare User's Selected Value
	const selection = feedback.selection.value[feedback.selection.key];
	// Render selected choice to selection div
	// Replace Input value with the selected value
	autoCompleteJS.input.value = selection;
	// Console log autoComplete data feedback
	Turbolinks.visit(feedback.selection.value.url);
});




  const fullNavbar = document.querySelector('.global-nav');
  const menuNavbar = document.querySelector('.nav_ul_end');
  const textNav = menuNavbar.querySelectorAll('p');
  const logo = document.querySelector('.logo');
  const linkBar = document.querySelectorAll('.linkocont');

  window.addEventListener('scroll', () => {
    function scrollWatcher () {
      
    const topElement = fullNavbar.getBoundingClientRect().top;

    if ((topElement < -80 ) && (window.matchMedia("(min-width: 1000px)").matches)) {
      textNav.forEach(textNav => {
        textNav.style.color = "black";
      })
      fullNavbar.style.marginTop = '4em';
      menuNavbar.style.flexDirection = 'column';
      menuNavbar.style.padding = '1em';
      menuNavbar.style.position = 'fixed';
      logo.classList.add('hidden');
      linkBar.marginTop = '0.1em';

    } else if ((topElement > 20) && (window.matchMedia("(min-width: 1000px)").matches)) {
      fullNavbar.style.marginTop = '0em';
      logo.classList.remove('hidden');
      menuNavbar.style.margin = '0em auto';
      menuNavbar.style.paddingTop = '1em';
      menuNavbar.style.paddingBottom = '1em';
      menuNavbar.style.paddingRight = '1em';
      menuNavbar.querySelector('p').style.color = '#fff';
      menuNavbar.style.flexDirection = null;
      menuNavbar.style.position = null;
      menuNavbar.style.marginTop = '-1.3em';
      menuNavbar.style.marginRight = '-1em';
      linkBar.marginTop = '0.1em';
      textNav.forEach(textNav => {
        textNav.style.color = "white";
      }
      )
    }};
    scrollWatcher();
  });


});

